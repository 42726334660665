import React, { useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
} from "react-bootstrap";
import { formatNumberWithout, getLink, validateEmail } from "../helper";
import FormSelect from "../components/widget/form-select/FormSelect";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faEye,
  faEyeSlash,
  faSignIn,
} from "@fortawesome/free-solid-svg-icons";

function Header() {
  const [passView, setPassView] = useState(true);
  const section3Ref = useRef(null);

  const handleRegister = async (e) => {
    e.preventDefault();

    const email = document.getElementById("email").value;
    const name = document.getElementById("name").value;
    const password = document.getElementById("email").value;
    const phone = document.getElementById("phone").value;
    const ig_username = document.getElementById("ig_username").value;
    const tiktok_username = document.getElementById("tiktok_username").value;
    const bank = document.getElementById("bank").value;
    const account_name = document.getElementById("account_name").value;
    const account_number = document.getElementById("account_number").value;

    if (!validateEmail(email)) {
      alert("Periksa Kembali Email Kamu");
    } else {
      const params = {
        email: email,
        name: name,
        password: password,
        phone: phone,
        ig_username: ig_username,
        tiktok_username: tiktok_username,
        bank: bank,
        account_name: account_name,
        account_number: account_number,
      };
      try {
        const response = await axios(
          `${process.env.REACT_APP_API_URL}/r_affiliator`,
          {
            headers: {
              "Content-Type": "application/json",
              token: process.env.REACT_APP_API_KEY,
            },
            method: "post",
            data: JSON.stringify(params),
          }
        );
        if (response.status === 200) {
          if (response.data.status) {
            alert(
              "Berhasil Mendaftar Menjadi Affiliator, Tunggu Whatsapp Dari Kami Yaa :)"
            );
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        } else {
          throw new Error("Request failed");
        }
      } catch (error) {}
    }
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Container className="text-black p-0" fluid>
      <Button
        className="btn-goto-wa"
        onClick={() =>
          getLink(
            `https://api.whatsapp.com/send/?phone=6282245020926&text=Halo+Admin+Pockets%2C+Saya+ingin+bertanya+tentang+Affiliator%20Pockets&type=phone_number&app_absent=0`
          )
        }
      />
      <Col className="hero p-0 text-center">
        <Col className="d-flex justify-content-between">
          <Col className="text-start p-4">
            <Image
              src={require("../images/mobile/pockets.png")}
              style={{ width: "80%" }}
            />
          </Col>
          <Col className="text-end p-4">
            <Button className="btn-login">
              Masuk <FontAwesomeIcon icon={faSignIn} />
            </Button>
          </Col>
        </Col>

        <Image
          src={require("../images/mobile/hero.png")}
          style={{ width: "60%" }}
        />
        <br />
        <br />
        <h4 className="poppins-medium">
          Ajak Teman, <br />
          Raih Cuan!
        </h4>

        <Button
          className="btn-affiliator"
          onClick={() => scrollToSection(section3Ref)}
        >
          DAFTAR MENJADI AFFILIATOR
        </Button>
      </Col>
      <Col className="benefit text-center">
        <Col className="d-flex justify-content-center align-items-center">
          <Image
            style={{ width: "10%" }}
            src={require("../images/mobile/eyes.png")}
          />
          <h4 className="poppins-medium-white">
            Ajak Teman, <br />
            Raih Cuan!
          </h4>
          <Image
            style={{ width: "10%" }}
            src={require("../images/mobile/mark.png")}
          />
        </Col>
        <Col className="d-flex justify-content-between">
          <Col xs={4} className="p-2">
            <Col className="benefit-card text-start">
              <h4 className="poppins-thin-white">
                1. Komisi dari setiap penjualan yang Anda referensikan
              </h4>
            </Col>
          </Col>
          <Col xs={4} className="p-2">
            <Col className="benefit-card text-start">
              <h4 className="poppins-thin-white">
                2. Akses eksklusif ke promosi dan penawaran khusus
              </h4>
            </Col>
          </Col>
          <Col xs={4} className="p-2">
            <Col className="benefit-card text-start">
              <h4 className="poppins-thin-white">
                3. Dukungan penuh untuk membantu Anda sukses
              </h4>
            </Col>
          </Col>
        </Col>
        <Col className="col-rincian">
          <h4 className="bold-border-text">Rincian Komisi</h4>
        </Col>
        <Col className="d-flex justify-content-center text-center">
          <h4 className="poppins-medium-small">1 TIKET</h4>
          <h4
            style={{
              marginInline: "1rem",
              fontWeight: 800,
              color: "rgba(1, 171, 151, 1)",
            }}
          >
            =
          </h4>
          <h4 className="poppins-medium-orange">
            Rp {formatNumberWithout(10000)}
          </h4>
        </Col>
        <Col className="d-flex justify-content-center text-center">
          <h4 className="poppins-medium-small">4 TIKET</h4>
          <h4
            style={{
              marginInline: "1rem",
              fontWeight: 800,
              color: "rgba(1, 171, 151, 1)",
            }}
          >
            =
          </h4>
          <h4 className="poppins-medium-orange">
            Rp {formatNumberWithout(50000)}
          </h4>
        </Col>
        <Col className="d-flex justify-content-center text-center">
          <h4 className="poppins-medium-small">10 TIKET</h4>
          <h4
            style={{
              marginInline: "1rem",
              fontWeight: 800,
              color: "rgba(1, 171, 151, 1)",
            }}
          >
            =
          </h4>
          <h4 className="poppins-medium-orange">
            Rp {formatNumberWithout(200000)}
          </h4>
        </Col>
        <Col className="d-flex justify-content-center text-center">
          <h4 className="poppins-medium-small">15 TIKET</h4>
          <h4
            style={{
              marginInline: "1rem",
              fontWeight: 800,
              color: "rgba(1, 171, 151, 1)",
            }}
          >
            =
          </h4>
          <h4 className="poppins-medium-orange">
            Rp {formatNumberWithout(250000)}
          </h4>
        </Col>
        <Col className="d-flex justify-content-center text-center">
          <h4 className="poppins-medium-small">50 TIKET</h4>
          <h4
            style={{
              marginInline: "1rem",
              fontWeight: 800,
              color: "rgba(1, 171, 151, 1)",
            }}
          >
            =
          </h4>
          <h4 className="poppins-medium-orange">
            Rp {formatNumberWithout(1000000)}
          </h4>
        </Col>
      </Col>
      <Col className="form-aff text-center" ref={section3Ref}>
        <Image
          src={require("../images/mobile/animation-3.png")}
          style={{ width: "60%" }}
        />
        <Form onSubmit={handleRegister} className="p-4 text-start">
          <Form.Label>Nama</Form.Label>
          <Form.Control
            placeholder="Nama"
            id="name"
            type="text"
            required
            className="mb-3"
          />
          <Form.Label>Email - </Form.Label>
          <span className="text-danger" style={{ fontWeight: "700" }}>
            {" "}
            Untuk Login Ke PocketsId
          </span>
          <Form.Control
            placeholder="Email"
            id="email"
            type="email"
            required
            className="mb-3"
          />
          <Form.Label>No. Handphone</Form.Label>
          <Form.Control
            placeholder="No. Handphone (08..)"
            id="phone"
            type="number"
            required
            className="mb-3"
          />
          <Form.Label>Password - </Form.Label>
          <span className="text-danger" style={{ fontWeight: "700" }}>
            {" "}
            Untuk Login Ke PocketsId
          </span>
          <InputGroup className="mb-3">
            <Form.Control
              type={!passView ? "text" : "password"}
              id="password"
              required
            />
            <Button
              style={{
                backgroundColor: "rgba(1, 171, 151, 1)",
                border: "1px solid rgba(1, 171, 151, 1)",
              }}
              onClick={() => setPassView(!passView)}
            >
              <FontAwesomeIcon icon={passView ? faEye : faEyeSlash} />
            </Button>
          </InputGroup>
          <Form.Label>Instagram User</Form.Label>
          <InputGroup className="mb-3">
            <Button
              style={{
                backgroundColor: "rgba(1, 171, 151, 1)",
                border: "1px solid rgba(1, 171, 151, 1)",
              }}
            >
              <FontAwesomeIcon icon={faAt} />
            </Button>
            <Form.Control type="text" id="ig_username" required />
          </InputGroup>
          <Form.Label>Tiktok User</Form.Label>
          <InputGroup className="mb-3">
            <Button
              style={{
                backgroundColor: "rgba(1, 171, 151, 1)",
                border: "1px solid rgba(1, 171, 151, 1)",
              }}
            >
              <FontAwesomeIcon icon={faAt} />
            </Button>
            <Form.Control type="text" id="tiktok_username" required />
          </InputGroup>
          <Form.Label>Nama Sesuai Rekening</Form.Label>
          <Form.Control
            placeholder=""
            id="account_name"
            type="text"
            required
            className="mb-3"
          />
          <Form.Label>Bank</Form.Label>
          <Form.Control
            placeholder="Pilih Bank"
            as={FormSelect}
            options={[
              { value: "BCA", label: "BCA" },
              { value: "BRI", label: "BRI" },
              { value: "MANDIRI", label: "MANDIRI" },
              { value: "BSI", label: "BSI" },
              { value: "BNI", label: "BNI" },
              { value: "CIMB", label: "CIMB" },
              { value: "CIMB", label: "CIMB" },
              { value: "SEABANK", label: "Seabank" },
            ]}
            id="bank"
            type="email"
            required
            className="mb-3"
          />
          <Form.Label>Nomor Rekening</Form.Label>
          <Form.Control
            placeholder=""
            id="account_number"
            type="number"
            required
            className="mb-3"
          />
          <Button
            type="submit"
            style={{ backgroundColor: "rgba(1, 171, 151, 1)", width: "100%" }}
          >
            DAFTAR
          </Button>
        </Form>
      </Col>
      <Col className="hero text-center py-3">
        <Image
          src={require("../images/mobile/pockets.png")}
          style={{ width: "50%" }}
        />
      </Col>
    </Container>
  );
}

export default Header;
